import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Paragraph1, Paragraph2, Paragraph3, Paragraph4, Paragraph5, Paragraph6 } from '@redoute/paragraphs';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "paragraph"
    }}>{`Paragraph`}</h1>
    <p>{`Six levels of Paragraphs`}</p>
    <Playground __position={0} __code={'<Paragraph1>\n  Commodo in anim dolore excepteur amet do exercitation incididunt nulla minim\n  cupidatat in anim.\n</Paragraph1>\n<Paragraph2>\n  Commodo in anim dolore excepteur amet do exercitation incididunt nulla minim\n  cupidatat in anim.\n</Paragraph2>\n<Paragraph3>\n  Commodo in anim dolore excepteur amet do exercitation incididunt nulla minim\n  cupidatat in anim.\n</Paragraph3>\n<Paragraph4>\n  Commodo in anim dolore excepteur amet do exercitation incididunt nulla minim\n  cupidatat in anim.\n</Paragraph4>\n<Paragraph5>\n  Commodo in anim dolore excepteur amet do exercitation incididunt nulla minim\n  cupidatat in anim.\n</Paragraph5>\n<Paragraph6>\n  Commodo in anim dolore excepteur amet do exercitation incididunt nulla minim\n  cupidatat in anim.\n</Paragraph6>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Paragraph1,
      Paragraph2,
      Paragraph3,
      Paragraph4,
      Paragraph5,
      Paragraph6,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Paragraph1 mdxType="Paragraph1">Commodo in anim dolore excepteur amet do exercitation incididunt nulla minim cupidatat in anim.</Paragraph1>
    <Paragraph2 mdxType="Paragraph2">Commodo in anim dolore excepteur amet do exercitation incididunt nulla minim cupidatat in anim.</Paragraph2>
    <Paragraph3 mdxType="Paragraph3">Commodo in anim dolore excepteur amet do exercitation incididunt nulla minim cupidatat in anim.</Paragraph3>
    <Paragraph4 mdxType="Paragraph4">Commodo in anim dolore excepteur amet do exercitation incididunt nulla minim cupidatat in anim.</Paragraph4>
    <Paragraph5 mdxType="Paragraph5">Commodo in anim dolore excepteur amet do exercitation incididunt nulla minim cupidatat in anim.</Paragraph5>
    <Paragraph6 mdxType="Paragraph6">Commodo in anim dolore excepteur amet do exercitation incididunt nulla minim cupidatat in anim.</Paragraph6>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      